<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Home",

  metaInfo: { title: "Beranda" },

  extends: View,

  mixins: [
    LoadSections([
      "slider",
      "news",
      "products",
      "cardinfo",
      // 'features',
      // // 'testimony',
      // 'affiliates',
      // 'news-latest',
      // 'awards',
      // "newsletter",

      "info",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "home",
    },
  },
};
</script>
